import React, { useEffect, useState,useContext, useRef } from "react";
import { useAxios } from "../../utils/axios";
import { useRouter } from 'next/router';
import ProductDetails from './ProductDetails';
import PriceBreakupTable from './PriceBreakUp';
import RelatedProducts from '../../components/ProductsGrid/RelatedProducts';
import SimilarProducts from '../../components/ProductsGrid/ProductsSlider';
import LoadingAnimation from '../../components/custom/LoadingAnimation';
import Link from "next/link";
import { UserContext } from '../../contexts/UserContext';
import { CurrencyContext } from "../../contexts/CurrencyContext";
import SEO from "../../components/custom/SEO";
import axios from "axios";
import { BUILDER_URL } from '../../utils/config';
import { ThemeContext } from "../../contexts/ThemeContext";
import ErrorBoundary from '../../components/ErrorBoundary';
import Custom404 from '../404';
import { useJobId } from '../../contexts/ContextJbo';
import errorLogApi from '../../components/custom/ErrorLogApi';
import useTracking from '../../components/custom/useTracking';
import { RedisContext } from "../../contexts/RedisContext";
/**
 * Function to transform B2C Price Breakup data.
 */
const transformB2CPriceBreakup = (b2cPriceBreakUp,isSpecial,userType) => {
  const { b2c_metal_category, b2c_stone, b2c_price, b2c_va_data,b2c_mc_data,b2c_dimond } = b2cPriceBreakUp;

  const transformedData = [];

  // Check and add Metal Category data if available
  if (b2c_metal_category?.length) {
    b2c_metal_category.map(metal => {
      const data = {
        Components: metal.metal_category_name,
        Purity: metal.Purity,
        Rate: metal.rate,
        Weight: metal.volume,
        Units: metal.measurement,
        Amount: metal.Amount,
      }
      // - removed
      if( (isSpecial == 1) && (userType == 0 || userType == 1)) data['specialprice'] = metal.Amount;
      transformedData.push(data);
    });
  }
  // Check and add Metal Category data if available
  if (b2c_mc_data?.length) {

   const data = {
    Components: 'Making Charge',
    Purity: '',
    Rate: b2c_mc_data[0].attribute_value,
    Weight: '',
    Units: b2c_mc_data[0].mc_type,
    Amount: b2c_mc_data[0].MC
  }
  // mc value updated
  if( (isSpecial == 1) && (userType == 0 || userType == 1)){
    if( b2c_mc_data[0].special_mc == 0){
       // - removed
      data['specialprice'] = b2c_mc_data[0].MC;
    }else{
      data['specialprice'] = b2c_mc_data[0].special_mc;
    }
  } 
  // Only push data if Making Charge Amount (MC) is not 0
  if (b2c_mc_data[0].MC !== 0) {
    transformedData.push(data);
  }

  }

  // Check and add Stone data if available
  if (b2c_stone?.length) {
    b2c_stone.map(stone => {
      const data = {
        Components: stone.metal_category_name,
        Purity: stone.Purity,
        Rate: stone.rate,
        Weight: stone.volume,
        Units: stone.measurement,
        Amount: stone.Amount 
      }
       // - removed
      if((isSpecial) && (userType == 0 || userType == 1)) data['specialprice'] = stone.Amount;
      transformedData.push(data);
    
    });
  }
  // Check and add Stone data if available
  if (b2c_dimond?.length) {
    b2c_dimond.map((diamond, index) => {
      const data = {
        Components: diamond.metal_category_name,  // Adding sequential numbers
        Purity: diamond.Purity,
        Rate: diamond.rate,
        Weight: diamond.volume,
        Units: diamond.measurement,
        Amount: diamond.Amount,
      }
       // - removed
      if( (isSpecial == 1) && (userType == 0 || userType == 1)) data['specialprice'] = diamond.Amount;
      transformedData.push(data);
    });
  }
 
  // Check and add VA Data if available
  if (b2c_va_data?.length) {
    const data = {
      Components: 'Wastage',
      Purity: '',
      Rate: b2c_va_data[0].attribute_value,
      Weight: '',
      Units: b2c_va_data[0].va_type,
      Amount: b2c_va_data[0].VA,
    };
    if( (isSpecial == 1) && (userType == 0 || userType == 1)){
      if( b2c_va_data[0].special_VA == 0){
        data['specialprice'] =  b2c_va_data[0].VA;
      }else{
        data['specialprice'] = b2c_va_data[0].special_VA;
      }
    } 
 
    // Only push data if Wastage Amount is not 0
    if (b2c_va_data[0].VA !== 0) {
      transformedData.push(data);
    }

  }

  // Check and add price-related categories if available
  if (b2c_price?.length) {
    const priceRelated = {
      'Taxable Amount': b2c_price[0].price,
      [`Tax (${b2c_price[0].tax_percentage}%)`]: b2c_price[0].Tax,
      'Final Amount': b2c_price[0].final_price,      
    };
    let index = 0;
    for (const [key, value] of Object.entries(priceRelated)) {
      if((isSpecial == 1) && (userType == 0 || userType == 1)){
        const data = [b2c_price[0].special_amount,b2c_price[0].special_tax,b2c_price[0].special_final_price]
        transformedData.push({
          Components: key,
          Purity: '',
          Rate: '',
          Weight: '',
          Units: '',
          Amount: value,
          specialprice: data[index]
        });
      }else{
        transformedData.push({
          Components: key,
          Purity: '',
          Rate: '',
          Weight: '',
          Units: '',
          Amount: value,
        });
      }
      index++;

    }
  }

  return transformedData;
};

/**
 * Function to transform B2B Price Breakup data.
 */
const transformB2BPriceBreakup = (b2bPriceBreakUp,b2c_price_break_up, formatCurrency) => {
// b2b mc value added -23/08/2024
  const { b2b_metal_category, b2b_stone, b2b_price,b2b_dimond,b2b_mc_data } = b2bPriceBreakUp;
  const { b2c_price } = b2c_price_break_up;
  const transformedData = [];

  // Add Metal Category data if available
  // if (b2b_metal_category?.length) {
  //   transformedData.push({
  //     Components:b2b_metal_category[0].metal_category_name,
  //     Purity: b2b_metal_category[0].Purity,
  //     Weight: b2b_metal_category[0].volume,
  //     Units: b2b_metal_category[0].measurement,
  //     Touch: b2b_price?.length ? b2b_price[0].purchase_touch : '',
  //     'Pure Weight': b2b_metal_category[0].pure_weight,
  //     'Pure Rate': formatCurrency(b2b_price[0].pure_rate),
  //     Amount: b2b_metal_category[0].Amount,
  //   });
  // }
  if (b2b_metal_category?.length) {
    b2b_metal_category.map(metal => {
      transformedData.push({
        Components: metal.metal_category_name,
        Purity: metal.Purity,
        Weight: metal.volume,
        Units: metal.measurement,
        Touch: b2b_price?.length ? b2b_price[0].purchase_touch : '',
        'Pure Weight': metal.pure_weight,
        'Pure Rate': formatCurrency(b2b_price?.length ? b2b_price[0].pure_rate : 0),
        Amount: metal.Amount,
      });
    });
  }
  // b2b mc value added -23/08/2024
   if (b2b_mc_data?.length  && b2b_mc_data[0].MC != 0) {

    const data = {
     Components: 'Making Charge',
     Purity: '',
     Weight: '',
     Units: '',
     Touch: '',
     'Pure Weight':'',
     'Pure Rate': '',
     Amount: b2b_mc_data[0].MC,


   }
    transformedData.push(data);
   }
  if (b2b_dimond?.length) {
    b2b_dimond.map(metal => {
      transformedData.push({
        Components: metal.metal_category_name,
        Purity: metal.Purity,
        Weight: metal.volume,
        Units: metal.measurement,
        Touch: '',
        'Pure Weight':'',
        'Pure Rate': '',
        Amount: metal.Amount,
      });
    });
  }

  // Add Stone data if available
  if (b2b_stone?.length) {
    b2b_stone.map(stone => {
      transformedData.push({
        Components: stone.metal_category_name,
        Purity: stone.Purity,
        Weight: stone.volume,
        Units: stone.measurement,
        Touch: '-',
        'Pure Weight': '-',
        'Pure Rate': formatCurrency(stone.rate),
        Amount: `${stone.Amount}`,
      });
    });
  }

  // Add price-related categories if available
  if (b2b_price?.length) {
    const priceRelated = {
      'Purchase Price': `${b2b_price[0].purchase_cost}`,
      [`Tax (${b2b_price[0].tax_percentage}%)`]: `${b2b_price[0].Tax}`,
      'Purchase Price With Tax': `${b2b_price[0].purchase_price_with_tax}`,
      'Selling Price': `${b2c_price[0].final_price}`,
      'Margin': `${b2b_price[0].margin}`,
    };

    for (const [key, value] of Object.entries(priceRelated)) {
      transformedData.push({
        Components: key,
        Purity: '',
        Weight: '',
        Touch: '',
        'Pure Weight': '',
        'Pure Rate': '',
        Units: '',
        Amount: value,
      });
    }
  }

  return transformedData;
};


  /**
 * ProductPage component for displaying detailed product information.
 *
 * Fetches product details based on the provided ID and displays related and similar products.
 * It also handles different user types and formats currency as needed.
 */
export default function ProductPage({serverReferrer}) {
  // redis implementation
  const {redisProductDetailPage} = useContext(RedisContext);
    const scrollRef = useRef(null);
    const theme = useContext(ThemeContext);
    // state for price breakup enable 
    const [priceBreakUpConsolidated,setPriceBreakUpConsolidate]=useState();

    const [product, setProduct] = useState({});
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [slugData, setSlugData] = useState();
    const [similarProducts, setSmilarProducts] = useState([]);
    const [snackBarProducts, setSnackBarProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [frameData, setFrameData] = useState([]);
    const { domainData ,setArEnable} = useContext(UserContext);
    const { jboId } = useJobId();
    const trackPage = useTracking('product Page');
    const axiosInstance = useAxios();
    const router = useRouter();
    const { slug } = router.query;
   

    // previlage status added
    const { userDetails,privilegeStatus  } = useContext(UserContext);
        const { formatCurrency } = useContext(CurrencyContext);
        const [SEOData, setSEOData] = useState({});

        const [productDetailsInfo,setProductDetailsInfo]=useState(redisProductDetailPage || "");

    const scrollToRef = () => {
      if (scrollRef.current) {
        const textContent = scrollRef.current.textContent == "B2C Price Breakup";
        const offsetTopWithSpace = textContent ? scrollRef.current.offsetTop - 600 : scrollRef.current.offsetTop - 100;

          window.scrollTo({
              top: offsetTopWithSpace,
              behavior: 'smooth'
          });
      }
    };

    
  // 302 redirection issue fix
    
    const fetchData = async () => {
   
      const productId = slug[1];//Get the Product id from the URL

      // Numeric IDs (\d+).
      // IDs starting with m followed by numbers (m\d+).
      // IDs with a prefix like c65p followed by numbers (c\d+p\d+).

      if (!/^(?:\d+|m\d+|c\d+p\d+)$/i.test(productId)) {
        setIsLoading(false);
        return;
    }
    

      const payload = {
        pro: productId,//the menu id is removed for URL encode option
        promo_code: '{}'
    };

      try {

      
        

const response = await axiosInstance.post(`product/getSingleProduct`, payload);


          if (response.data) {
            // data to store consolidated value
            const productData = response.data.product[0];
            setPriceBreakUpConsolidate(productData.consolidated);
            setArEnable(productData?.ar_enable)
              const detProductData = response.data;
              //Get the previous menu Url from the getServerSideProps for Breadcrumbs
              const path = serverReferrer !== '/' ? new URL(serverReferrer).pathname : null;
              let menuUrl = '';                    
              if (path) {
                const menuSlugs = path.replace(/^\/|\/$/g, '').split('/');                    
                // Regular expression to check if a string is entirely numbers
                const isOnlyNumbers = (slug) => /^\d+$/.test(slug);                    
                // Check if path has exactly 3 slugs, the last slug is not entirely numbers, and doesn't include 'buy'
                if (
                  menuSlugs.length === 3 &&
                  !isOnlyNumbers(menuSlugs[2]) && // Ensure the last slug is not only numbers
                  !menuSlugs[2].includes('buy')
                ) {
                  // The path has 3 valid slugs, and the last slug is not only numbers or does not include 'buy'
                  menuUrl = path;
                } else {
                  // The path either doesn't have exactly 3 slugs, the last slug contains only numbers, or includes 'buy'
                }
              }
             
              setSlugData(menuUrl);//Pass the url to Single Product page
             
             
             


const seoData = {
title: detProductData.page_property[0].seo_title,
description:detProductData.page_property[0].seo_description,
keyword: detProductData.page_property[0].seo_keyword
};
    setSEOData(seoData);
    var ogUrl = window.location.href;
    var ogCurrency = detProductData.page_property[0].currency;
    var ogAvailability = productData.Availability == 1 ? "https://schema.org/InStock" : "on_display_to_order";
    var ogAmount = productData.json.price;
    var ogImage = window.location.origin + '/product_image/s1200__' + detProductData["images"]["base_image"];
    var ogDescription = detProductData.page_property[0].seo_description;
    var ogProductName = productData["product_name"];
    var ogTags = ["product", ogProductName, ogDescription, ogUrl, ogImage, ogAmount, ogCurrency, ogAvailability];
    var ogNames = ["og:type", "og:title", "og:description", "og:url", "og:image", "og:price:amount", "og:price:currency", "og:availability"]


              if (productData.Availability != 1) {
                  const currentDate = new Date();
                  const futureDate = new Date(currentDate);
                  futureDate.setDate(currentDate.getDate() + 21);
                  var formattedFutureDate = futureDate.toISOString().split('T')[0];
                  var ogAvailabilityDate = formattedFutureDate;
                  var ogAvailabilityDateName = "og:availability_date";
                  ogTags.push(ogAvailabilityDate)
                  ogNames.push(ogAvailabilityDateName)
              }
if(ogTags.length > 3){
for (let i = 0; i < ogTags.length; i++) {
var scriptElement = document.createElement("meta");
scriptElement.setAttribute("property", ogNames[i])
scriptElement.content = ogTags[i];
document.head.appendChild(scriptElement);
}    
}


var scriptElementSeo = document.createElement("script");
    // var siteName = sessionStorage.site_name;
    // var siteName = window.location.hostname;
    var siteName = domainData.seo_domain_name || window.location.hostname;




   var additionalCodes = `   {
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "` + ogProductName + `",
  "url": "` + window.location.href + `",
  "description": "` + ogDescription + `",
  "image": ` + ogImage+ `,
  "offers": {
    "@type": "Offer",                  
    "priceCurrency": "INR",
    "price": ` + ogAmount + `,
    "availability": "` + ogAvailability + `",
    "quantity":"1",
    ${ogAvailabilityDate ? '"availability_date": "' + ogAvailabilityDate + '",' : ''}
    "itemCondition": "https://schema.org/NewCondition",
    "seller": {
      "@type": "Organization",
      "name": "` + siteName + `"
  }


  }
 
 
 }`
    scriptElementSeo.type = "application/ld+json";
    scriptElementSeo.textContent = additionalCodes;
    document.head.appendChild(scriptElementSeo);
         
   


           
    // Check if b2b_price_break_up and b2c_price_break_up have data
    const hasB2BData = response.data?.b2b_price_break_up?.b2b_price?.length > 0;
    const hasB2CData = response.data?.b2c_price_break_up?.b2c_price?.length > 0;


 


    // Transform data only if it exists
    const transformedB2BData = hasB2BData ? transformB2BPriceBreakup(response.data?.b2b_price_break_up,response.data?.b2c_price_break_up, formatCurrency) : null;
    const transformedB2CData = hasB2CData ? transformB2CPriceBreakup(response.data?.b2c_price_break_up,productData.special_price_status,userType) : null;


    setProduct({
        ...detProductData,
        ...productData,
        images: response.data.images,
        b2b_price_break_up: transformedB2BData, // Set to transformed data or null
        b2c_price_break_up: transformedB2CData, // Set to transformed data or null
        price: productData.json.price,
        specialPrice :productData.special_price,  //Price Rule value
        isSpecialPrice :productData.special_price_status // Check if the price rule is enabled or not
    });            // Transform related products data
              setSnackBarProducts({
                  categoryName: productData?.category_name,
                  image:  response.data.images.base_image || '',
                  name:productData.product_name,
                  id: productData.product_id,
                  price: productData.json.price || 0,
                  productCount: 1, // Assuming a default count of 1


                  // Include any other fields you need from productData or its json
              });
             
             
      // Transform related products data
      const transformedRelatedProducts = response.data.related_product.map(product => ({
          id: product.product_id,
          mProductId:product?.product_id1,
          name: product.product_name || '',
          price: product.json.price || 0,
          categoryName: product.json['category_name'] || '',
          weight: product.json.weight || null,
          url:product.url,
          // touch:product.json.weight || null,
          image: product.images || '',
          hoverImage: product?.thumbnail_image[1]?.thumbnail_image || '',
          isRemoved: false,
          touch: product.json.touch,
          productCount: 1,
          specialPrice :product.special_price,  //Price Rule value
          isSpecialPrice :product.special_price_status // Check if the price rule is enabled or not
      }));


      setRelatedProducts(transformedRelatedProducts);
      const transformedSimilarProducts = response.data.similar_product.map(product => ({
        id: product.product_id,
          // model id added
        mProductId:product?.product_id1,
        name: product.product_name || '',
        price: product.json.price || 0,
        categoryName: product.json['category_name'] || '',
        weight: product.json.weight || null,
        url:product.url,
        // touch:product.json.weight || null,
        image: product.images || '',
        hoverImage: product?.thumbnail_image[1]?.thumbnail_image || '',
        isRemoved: false,
        touch: product.json.touch,
        productCount: 1,
        specialPrice :product.special_price,  //Price Rule value
        isSpecialPrice :product.special_price_status // Check if the price rule is enabled or not
      }));
     
      setSmilarProducts(transformedSimilarProducts);


              setFrameData(response.data.frame_data || []);
             
          }
          else{
              // Check if the current URL contains 'http://localhost:3000'
    let currentUrl = window.location.href;
  
    if (currentUrl.includes('http://localhost:3000')) {
      // Replace 'http://localhost:3000' with the desired prefix using the environment variable
      currentUrl = currentUrl.replace('http://localhost:3000', `https://${process.env.NEXT_PUBLIC_WEB_HOST}`);
    }
  
    const redirectResponse = await axiosInstance.post('user/viewRedirect302', {
      old_url: currentUrl,
    });
    if (redirectResponse.data.status == 1) {
          
      window.location.href = redirectResponse.data.redirect[0].new_url;
    }

          }


}

        
        
       catch (error) {
        const jboID = jboId;
        let currentUrl = window.location.href;
  
    if (currentUrl.includes('http://localhost:3000')) {
      // Replace 'http://localhost:3000' with the desired prefix using the environment variable
      currentUrl = currentUrl.replace('http://localhost:3000', `https://${process.env.NEXT_PUBLIC_WEB_HOST}`);
    }
  
    const redirectResponse = await axiosInstance.post('user/viewRedirect302', {
      old_url: currentUrl,
    });
    if (redirectResponse.data.status == 1) {
          
      window.location.href = redirectResponse.data.redirect[0].new_url;
    }
        const endpoint = 'product/getSingleProduct';
        const productId = slug[1];
        const payLoad = {
          pro: productId,
          promo_code: '{}'
      };
      const responseData = error?.response?.data ? error.response.data : '';
        const errorMsg = error;
        errorLogApi({
          job_id: jboID,
          endpoint: endpoint,
          payload: payLoad,
          responseData: responseData,
          errorMsg: errorMsg
        });
      } finally {
          setIsLoading(false);
      }
  };


  useEffect(() => {
    
    fetchData();
 
  
  
      
  
  }, []);
  
  


    useEffect(() => {
        if (!slug) return;
      
        trackPage();
      
    }, [slug]);
    const BASE_URL = BUILDER_URL;


    // useEffect(() => {
    //   const aspath = router.asPath;
    //   const newPathname = aspath.replace('/product', '');
   
    //   router.push(`${newPathname}`);
    // }, []);


    // Conditonal rendering for b2b and b2c user
    const userType = userDetails ?. user_type || 0;
    const isB2BUser = userType === 3;
    const isB2CUser = userType === 1 || userType === 0 || !userDetails ;
    const isOwner = userType === 2;
   

    const showB2CTable = isB2CUser || isOwner || isB2BUser;
    const showB2BTable = isOwner || isB2BUser;
    const showPurchaseCost = isOwner || isB2BUser ;
 
// Handle loading state or missing product details
if (isLoading) {
  return <LoadingAnimation />;
}

if (!productDetailsInfo) {
  return <Custom404 />;
}



    return (
      <>
      
        <ErrorBoundary>
           <SEO 
        title={SEOData?.title} 
        description={SEOData?.description} 
        keyword={SEOData?.keyword} 
      />
            {/* Main Container */}
            <div className="block xl:container px-4 py-4 ">
                <div className="flex flex-col space-y-4 md:space-y-10">
                  
                <>
  {/* Always render Product Details Section */}
  <ProductDetails priceBreakUpConsolidated = {priceBreakUpConsolidated} slugData={slugData} snackBarProducts={snackBarProducts} product={product} productDetailsInfo={productDetailsInfo} frameData={frameData} scrollToRef={scrollToRef} />

  {/* Conditionally render Price Breakup Tables based on price_breakdown */}
  {(productDetailsInfo?.price_breakdown === 1 && priceBreakUpConsolidated == 0) && (
    <>
      {/* Render B2B Price Breakup Table if applicable */}
      {showB2BTable && product.b2b_price_break_up && (
        <>
          <span ref={scrollRef} className="uppercase text-2xl pt-8 md:pt-0 md:text-xl text-gray-800 font-semibold flex justify-center lg:justify-start items-center">
            B2B Price Breakup
          </span>
          <PriceBreakupTable data={product.b2b_price_break_up} showPurchaseCost={showPurchaseCost} />
        </>
      )}

      {/* Render B2C Price Breakup Table if applicable */}
      {showB2CTable && product.b2c_price_break_up && (
        <>
          <h3 ref={scrollRef} className="uppercase text-2xl md:text-2xl pt-8 md:pt-0 text-gray-800 font-semibold flex justify-center lg:justify-start items-center">
            {userDetails?.user_type === 1 || userDetails?.user_type === 0 || !userDetails ? "Price Breakup" : "B2C Price Breakup"}
          </h3>
          <PriceBreakupTable data={product.b2c_price_break_up} />
        </>
      )}
    
    {/* Privilege customer  */}
    {((product.isSpecialPrice == 1) && (userType == 1 || userType == 0))&&
    <>
    {(jboId === 233) && ((privilegeStatus==0 || !privilegeStatus) ? (
      <div className="border p-4 mb-6 mt-4 rounded-lg shadow-md transition-transform transform hover:shadow-lg">
        <h2 className="font-bold text-primary text-sm mb-1">
          Special price is for Privilege Customers Only
        </h2>
        <p className="text-primary text-sm mb-2">
          Become a Privilege Customer for free. Register now!
        </p>
        <button
          onClick={() => router.push('/auth/Register')}
          className="bg-primary text-white text-sm font-semibold py-1 px-4 rounded transition duration-200 shadow-sm hover:shadow-md"
        >
          Register Now  
        </button>
      </div>
    ) : (
      <div className="p-4 mb-6 mt-4 rounded-md shadow-lg transition-transform transform">
        <p className="text-primary text-sm mb-2 font-normal">
          Apply your privilege number at checkout to claim this offer
        </p>
      </div>
    ))}</>}
    </>
  )}
</>

                </div>
            </div>
            {/* conditional based rendering */}
            {productDetailsInfo?.general_disclaimer  && <div
          className="container shadow-sm mt-2 p-4 md:p-2 text-sm sm:text-base text-justify"
          dangerouslySetInnerHTML={{
            __html:productDetailsInfo?.general_disclaimer !== "" ? 
            productDetailsInfo?.general_disclaimer.replace(/<p>/g, '<p class="mb-4">') : // Add margin to paragraphs
              ""
          }}
        >
       
        </div> }
            {/* empty commits */}
            {/* Similar Products Section (outside the main container) */}
            {similarProducts && similarProducts.length !==0 && (
                <div className="block xl:container px-4 pb-16 pt-8">
                    <h3 className="uppercase text-2xl md:text-2xl text-gray-800 mb-6 font-semibold flex justify-center lg:justify-start items-center">
                        Similar Products
                    </h3>
                    <RelatedProducts products={similarProducts} isLoading={isLoading} isSpecificGrid={true}/>
                </div>
            )}

            {/* Main Container Continued */}
          
                <div className="flex flex-col space-y-10">
                    {relatedProducts && relatedProducts.length !==0 && (
                        <div className="block xl:container px-4 pb-16">
                            <h3 className="uppercase text-2xl md:text-2xl text-gray-800 mb-6 font-semibold flex justify-center lg:justify-start items-center">
                                Related Products
                            </h3>
                            <RelatedProducts products={relatedProducts} isLoading={isLoading} isSpecificGrid={true}/>
                        </div>
                    )}
                </div>
           
        </ErrorBoundary>
      </>
    );
    
}

