import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { CurrencyContext } from "../../contexts/CurrencyContext";
import { useInView } from 'react-intersection-observer';

/**
 * PriceBreakupTable component for displaying a detailed price breakup.
 *
 * This component takes data of price breakups and formats it into a table or card layout.
 * It uses Framer Motion for animations and Intersection Observer for triggering animations
 * when the component comes into view.
 *
 * @param {Array} data - Array of price breakup data.
 * @param {boolean} showPurchaseCost - Flag to indicate whether to show purchase cost or not.
 */
const PriceBreakupTable = ({ data,showPurchaseCost }) => { 
    
    const { formatCurrency } = useContext(CurrencyContext);

      // Motion variants for row and cell animations
    const rowVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0 },
      };
      
      const cellVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      };
      // Intersection Observer hook to trigger animation when in view
      const [ref, inView] = useInView({
        triggerOnce: true, // Trigger the animation only once
        threshold: 0.1 // 10% of the element should be visible before triggering
    });

    // Function to determine if the row should be highlighted
    const shouldHighlightRow = (item) => {
        // List all the component names that should be highlighted
        const componentsToHighlight = [
            'Purchase Price',
            'Purchase Price With Tax', 
            'Taxable Amount', 
            'Final Amount',
            // Add any other component names that should be highlighted
        ];
        
        // Check if the 'Components' property matches any of the names in the list
        return componentsToHighlight.includes(item['Components']);
    };
    // Function to format values as currency
    const formatValue = (key, value) => {
        const currencyFields = [
            'price', 'amount', 'cost', 'tax', 
            'purchase_price_with_tax', 'final_price', 'total', 
            'purchase_cost', 'margin', 'purchase_touch','','specialprice' // Ensure 'purchase_touch' is here
        ];
        
        // Trim the key to remove any accidental whitespace
        const trimmedKey = key.trim();
    
        // Check if the value is not null, undefined, or an empty string
        const isValueNotEmpty = value !== null && value !== undefined && value !== ''&& value !== '-';
        
        // Format as currency if the key is a currency field and the value is not empty
        return currencyFields.includes(trimmedKey.toLowerCase()) && isValueNotEmpty ? formatCurrency(value) : value;
    };
    

    // Function to create table headers
    const createHeaders = (dataObj) => {
        return Object.keys(dataObj).map((key) => (
            <motion.th 
                key={key} 
                className="px-4 py-2 text-left text-md xl:text-xl font-semibold uppercase tracking-wider bg-gold-500 text-cream-500"
            >
                {key.replace(/_/g, ' ')}
            </motion.th>
        ));
    };

    // Function to render table rows
 // Function to render table rows
 const renderTableRows = (dataArr) => {
    return dataArr.map((item, index) => {
        if (!showPurchaseCost && (item['Components'] === 'Purchase Price' || item['Components'] === 'Purchase Price With Tax')) {
            return null;
        }

        const highlight = shouldHighlightRow(item);
        return (
            <motion.tr
            key={index}
            variants={rowVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"} // Trigger animation based on inView
            transition={{ duration: 0.5, delay: index * 0.1 }} // staggered effect
            className={`border-b ${highlight ? 'bg-gold-100' : 'bg-white'}`}
        >
            {Object.entries(item).map(([key, val], idx) => (
                <motion.td
                key={idx}
                variants={cellVariants}
                initial="hidden"
                animate={inView ? "visible" : "hidden"} // Trigger animation based on inView
                transition={{ duration: 0.5 }}
                className={`px-4 py-2 ${highlight ? 'font-semibold' : 'text-gray-800'} text-xs sm:text-xs lg:text-lg`}
              >
              
                    {formatValue(key, val)}
                </motion.td>
            ))}
        </motion.tr>
        );
    });
};
// render cards function
const renderCards = (dataArr) => {
    // If there is no data, don't render anything
    if (dataArr.length === 0) return null;

    return (
        <div className="bg-white p-4 rounded-lg shadow mb-4">
            {dataArr.map((item, index) => (
                <div key={index} className={`flex flex-col py-2 mb-3 ${shouldHighlightRow(item) ? 'bg-gold-100' : 'bg-gray-100'} rounded-lg`}>
                    {Object.entries(item).map(([key, val], idx) => {
                        // Skip rendering if the value is empty or a dash
                        if (!val || val === '-' || String(val).trim() === '') return null;
                        return (
                            <div key={idx} className="flex px-3 justify-between items-center py-1 border-b last:border-b-0">
                                <span className="text-gray-800 font-medium capitalize">{key.replace(/_/g, ' ')}</span>
                                <span className={`font-semibold ${shouldHighlightRow(item) ? 'text-black' : 'text-gray-600'}`}>
                                    {formatValue(key, val)}
                                </span>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};


    return (
        <div     ref={ref} className="block xl:container  my-8 p-4 sm:p-6 rounded-lg shadow-sm bg-cream-500 " style={{marginTop:"5px"}}>
        {/* Table view for larger screens */}
        <div className="overflow-x-auto custom-scrollbar">
            <table className="min-w-full leading-normal capitalize">
                <thead>
                    {data.length > 0 && (
                        <tr>{createHeaders(data[0])}</tr>
                    )}
                </thead>
                <tbody>
                    {renderTableRows(data)}
                </tbody>
            </table>
        </div>
        {/* Card view for mobile screens */}
        {/* <div className="sm:hidden">
            {renderCards(data)}
        </div> */}
    </div>
    );
};

export default PriceBreakupTable;
